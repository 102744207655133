export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [0];

export const dictionary = {
		"/(public)": [45,[6]],
		"/(public)/access": [46,[6,7]],
		"/(public)/access/verify": [47,[6,7]],
		"/(app)/account": [20,[2]],
		"/(app)/account/device": [21,[2]],
		"/(public)/auth/email/verify": [48,[6]],
		"/(public)/auth/forgot-password": [49,[6]],
		"/(public)/auth/reset-password": [50,[6]],
		"/(app)/challenges": [22,[2,3]],
		"/(app)/challenges/event/join/[groupCode]": [27,[2,3]],
		"/(app)/challenges/event/join/[groupCode]/[teamCode]": [28,[2,3]],
		"/(app)/challenges/event/[groupKey]": [24,[2,3]],
		"/(app)/challenges/event/[groupKey]/info": [25,[2,3]],
		"/(app)/challenges/event/[groupKey]/leaderboard": [26,[2,3]],
		"/(app)/challenges/series": [29,[2,3]],
		"/(app)/challenges/[challengeKey]": [23,[2,3]],
		"/(app)/contact": [30,[2]],
		"/(app)/dashboard": [31,[2]],
		"/(public)/redirect": [51,[6]],
		"/(app)/spyglass/datasets": [32,[2,4]],
		"/(app)/spyglass/projects": [33,[2,4]],
		"/(app)/spyglass/projects/[projectKey]": [34,[2,4]],
		"/(app)/spyglass/projects/[projectKey]/[runKey]": [35,[2,4]],
		"/(app)/spyglass/scorers": [36,[2,4]],
		"/(app)/spyglass/signing": [37,[2,4]],
		"/(app)/spyglass/targets": [38,[2,4]],
		"/(app)/strikes": [39,[2,5]],
		"/(app)/strikes/agents": [41,[2,5]],
		"/(app)/strikes/agents/[agentKey]/runs/[runId]": [42,[2,5]],
		"/(app)/strikes/overview": [43,[2,5]],
		"/(app)/strikes/runs/[shareId]": [44,[2,5]],
		"/(app)/strikes/[strikeKey]": [40,[2,5]],
		"/(app)/[sandbox=dev]": [8,[2]],
		"/(app)/[sandbox=dev]/stories": [9,[2]],
		"/(app)/[sandbox=dev]/stories/avatar": [10,[2]],
		"/(app)/[sandbox=dev]/stories/button": [11,[2]],
		"/(app)/[sandbox=dev]/stories/code-block": [12,[2]],
		"/(app)/[sandbox=dev]/stories/colors": [13,[2]],
		"/(app)/[sandbox=dev]/stories/drawer": [14,[2]],
		"/(app)/[sandbox=dev]/stories/item-dropdown": [15,[2]],
		"/(app)/[sandbox=dev]/stories/json-table": [16,[2]],
		"/(app)/[sandbox=dev]/stories/panel-tab": [17,[2]],
		"/(app)/[sandbox=dev]/stories/pill": [18,[2]],
		"/(app)/[sandbox=dev]/stories/text-input": [19,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';